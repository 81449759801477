#createform .MuiTextField-root {
  margin: 3px !important;
}

.MuiAutocomplete-inputRoot{
  padding: 0!important;
}
.card .MuiInputBase-input {
  padding: 8px!important;
  background-color: #fff;
}

.MuiInputBase-root {
  background-color: #fff;

}

.card .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
}

#createPayment .MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 0px!important;
}

.MuiRadio-root {
  padding: 5px!important;
}