.container {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: row;
}

.description {
  font-size: 20px;
  margin-bottom: 20px;
}
.card {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #fff;
}

/* Add rounded corners to the top left and the top right corner of the image */
img {
  border-radius: 5px 5px 0 0;
}

.button {
  font-size: 16px;
  color: #fff;
  background: transparent;
  border: #fff solid 2px;
  padding: 15px 50px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  outline: none;
  border-radius: 100px;
  margin-bottom: 20px;
  text-decoration: none;
}

.button:hover {
  background: #fff;
  color: black;
}

.split {
  width: 50%;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.split.left {
  background: url("../left2.jpg") center center no-repeat;
  background-size: cover;
}

.right {
  background: url("../right2.jpg") center center no-repeat;
  background-size: cover;
}

/*.logo {
  position: absolute;
  bottom: 0;
  right: 0;
}
*/
@media (max-width: 800px) {
  .split {
    width: 100%;
    overflow: auto;
    min-height: 500px;
  }
  .container {
    flex-direction: column;
  }
  /*.logo {
    position: fixed;
    bottom: 0;
    right: 0;
  }*/
}

.MuiFormLabel-root {
  z-index: 1000;
}